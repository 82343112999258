/* Glide */
import Glide from '@glidejs/glide'
window.Glide = Glide;

/* Alpine JS */
import Alpine from 'alpinejs';
import focus from '@alpinejs/focus'
import intersect from '@alpinejs/intersect'
 
Alpine.plugin(focus)
Alpine.plugin(intersect)
window.Alpine = Alpine;
Alpine.start();

/**
 * Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log("HMR")
  });
}